$color_1: #fff;
$color_2: #1d2b49;
$color_3: #d1d1d1;
$color_4: #121d35;
$color_5: #142348;
$color_6: #5e6470;
$color_7: #3d72a3;
$color_8: #959595;
$color_9: #33608c;
$color_10: #535d72;
$color_11: #ed1526;
$color_12: #313e59;
$color_13: #5e5e5e;
$color_14: #747c8d;
$color_15: #bacfff;
$color_16: #1b2a48;
$color_17: #CACACA;
$font-family_1: 'Raleway-Black';
$font-family_2: 'Raleway-Black';
$font-family_3: 'Raleway-Black';
$font-family_4: 'Sansation-Regular';
$font-family_5: 'Raleway-Black';
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
}
body {
	margin: 0;
	padding: 0;
	font-family: $font-family_1;
}
img {
	border: 0;
}
h1 {
	margin: 0;
	padding: 0;
	font-weight: normal;
}
h2 {
	margin: 0;
	padding: 0;
	font-weight: normal;
}
h3 {
	margin: 0;
	padding: 0;
	font-weight: normal;
}
h4 {
	margin: 0;
	padding: 0;
	font-weight: normal;
}
h5 {
	margin: 0;
	padding: 0;
	font-weight: normal;
}
h6 {
	margin: 0;
	padding: 0;
	font-weight: normal;
}
ul {
	margin: 0;
	padding: 0;
	// list-style-position: inside;
}
// ol li:nth-child(n+1):nth-child(-n+9) { margin-left: @include 55em; }

.float-left {
	float: left;
}
.auto_container {
	width: 1170px;
	margin: auto;
}
.main-outer {
	background: url(./images/bg-1.jpg) center top no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.pad-none {
	padding-left: 0;
	padding-right: 0;
}
.logo {
	padding: 20px 0 0;
}
nav {
	float: right;
	li {
		float: left;
		a {
			font-size: 16px;
			color: $color_1;
			display: block;
			padding: 21px 24px 18px 10px;
			&:hover {
				color: $color_1;
				text-decoration: none;
			}
		}
		&:last-child {
			a {
				padding-left: 32px;
				padding-right: 0;
			}
		}
	}
}
.sign-in {
	display: block;
	padding: 21px 24px 18px;
	background-color: #d7192b;
	border-left: 3px solid #bb121f;
	border-right: 3px solid #bb121f;
	&:focus {
		background-color: #d7192b !important;
		border-left: 3px solid #bb121f !important;
		border-right: 3px solid #bb121f !important;
		color: $color_1 !important;
	}
	&:hover {
		background-color: #d7192b !important;
		border-left: 3px solid #bb121f !important;
		border-right: 3px solid #bb121f !important;
		color: $color_1 !important;
	}
}
.sign-up {
	display: block;
	padding: 21px 33px 18px 22px;
	background-color: #1c2a47;
	&:focus {
		background-color: #1c2a47 !important;
		color: $color_1 !important;
	}
	&:hover {
		background-color: #1c2a47 !important;
		color: $color_1 !important;
	}
}
.dropdown {
	open {
		&:focus {
			background-color: transparent !important;
		}
		&:hover {
			background-color: transparent !important;
		}
	}
}
.support {
	margin-left: 6px;
}
.sign-in-dropdown {
	width: 383px;
	display: inline-block;
	background-color: #d7192b;
	padding: 30px 23px 23px;
	.input-group-addon {
		height: 46px;
		background-color: #b40000;
		text-align: center;
		border: 0;
		border-radius: 0;
		padding: 0 16px;
	}
	.input-group {
		margin-bottom: 16px;
	}
	.form-control {
		color: $color_2 !important;
		font-size: 13px !important;
		height: 46px;
		border-radius: 0;
	}
}
.login-btn-outer {
	width: 100%;
	float: left;
	text-align: center;
	margin-top: 4px;
}
.login-btn {
	background-color: #092d57;
	color: $color_1;
	font-size: 13px;
	border: 0;
	padding: 11px 63px;
	border-radius: 0;
	position: relative;
	&:hover {
		background-color: #092d57;
		color: $color_1;
	}
	&::before {
		content: url(./images/blue-left-arrow.png);
		position: absolute;
		left: -11px;
		top: 0;
	}
	&::after {
		content: url(./images/blue-right-arrow.png);
		position: absolute;
		right: -11px;
		top: 0;
	}
	&:focus {
		background-color: #092d57 !important;
		color: $color_1 !important;
	}
}
.slider-block {
	text-align: center;
	padding: 62px 0 35px;
	h1 {
		color: $color_1;
		text-transform: uppercase;
		font-size: 97px;
		padding: 0 100px;
		p {
			display: inline-block;
			line-height: 80px;
			margin: 32px 0 59px;
		}
	}
}
.top-border {
	float: left;
}
.bottom-border {
	float: right;
}
.get-started-outer {
	width: 100%;
	float: left;
	text-align: center;
	margin-top: -30px;
}
.get-started {
	background-color: #ff0d22;
	color: $color_1;
	font-size: 16px;
	border: 0;
	padding: 18px 50px 16px;
	border-radius: 0;
	position: relative;
	display: inline-block;
	&:focus {
		background-color: #ff0d22;
		color: $color_1;
		text-decoration: none;
	}
	&:hover {
		background-color: #ff0d22;
		color: $color_1;
		text-decoration: none;
	}
	&::before {
		content: url(./images/red-left-arrow.png);
		position: absolute;
		left: -15px;
		top: 0;
	}
	&::after {
		content: url(./images/red-right-arrow.png);
		position: absolute;
		right: -16px;
		top: 0;
	}
}
.pagination {
	width: 100%;
	float: left;
	text-align: center;
	li {
		display: inline-block;
		margin-left: 7px;
		&:first-child {
			margin-left: 0;
		}
	}
}
.how-it-work {
	background: rgba(18,29,53,1);
	background: -moz-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(18,29,53,1)), color-stop(100%, rgba(35,51,84,1)));
	background: -webkit-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: -o-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: -ms-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: linear-gradient(to right, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	padding: 27px 48px;
	margin: 35px 0 95px;
	display: inline-block;
	li {
		float: left;
		background: url(./images/border-img.png) right top no-repeat;
		text-align: center;
		width: 33.3%;
		padding: 29px 0;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			background: none;
			padding-right: 0;
		}
	}
}
.work-inner {
	width: 240px;
	margin: auto;
	img {
		float: left;
		margin-right: 24px;
	}
	>p {
		text-align: left;
		line-height: 27px;
		font-size: 22px;
		color: $color_3;
		margin: 0;
		text-transform: uppercase;
	}
}
.learn-more-outer-left {
	bottom: -49px;
	float: left;
	margin: 16px 0 -56px;
	position: relative;
	text-align: center;
	width: 100%;
}
.learn-more-outer-right {
	bottom: -27px;
	float: left;
	margin: 16px 0 -56px;
	position: relative;
	text-align: center;
	width: 100%;
}
.red-learn-more {
	background-color: #ff0d22;
	color: $color_1;
	font-size: 16px;
	border: 0;
	padding: 17px 60px 17px;
	border-radius: 0;
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	&:hover {
		background-color: #ff0d22;
		color: $color_1;
		text-decoration: none;
	}
	&::before {
		content: url(./images/red-left-arrow.png);
		position: absolute;
		left: -15px;
		top: 0;
	}
	&::after {
		content: url(./images/red-right-arrow.png);
		position: absolute;
		right: -15px;
		top: 0;
	}
}
.red-button {
	background-color: #d7182b;
	color: $color_1;
	font-size: 17px;
	border: 0;
	padding: 13px 30px 13px;
	border-radius: 0;
	display: inline-block;
	text-transform: uppercase;
  vertical-align: top;
	&:hover {
		background-color: #d7182b;
		color: $color_1;
		text-decoration: none;
	}
}
.blue-button {
	background-color: #1c2a47;
	color: $color_1;
	font-size: 17px;
	border: 0;
	padding: 13px 30px 13px;
	border-radius: 0;
	display: inline-block;
	text-transform: uppercase;
  vertical-align: top;
	&:hover {
		background-color: #1c2a47;
		color: $color_1;
		text-decoration: none;
	}
}
.grey-btn {
	background-color: #e4e4e4;
	color: $color_4;
	&:hover {
		background-color: #e4e4e4;
		color: $color_4;
	}
	&::before {
		content: url(./images/grey-left-arrow.png);
	}
	&::after {
		content: url(./images/grey-right-arrow.png);
	}
}
footer {
	background-color: #0b1326;
	padding: 50px 0 39px;
	text-align: center;
	float: left;
	width: 100%;
	li {
		display: inline-block;
		margin-left: 22px;
		a {
			color: $color_5;
			font-size: 22px;
		}
		&:first-child {
			margin-left: 0;
		}
	}
	p {
		color: $color_6;
		font-size: 11px;
		font-family: $font-family_2;
		font-weight: 400;
		margin-top: 12px;
	}
}
.template-main {
	background: url(./images/bg-2.jpg) center top no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.faq-main {
	background: url(./images/faq-bg.jpg) center top no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.faq-quesion-inner {
	padding: 0 0 133px;
}
.faq-banner {
	display: inline-block;
	position: relative;
	text-align: center;
	width: 742px;
	h1 {
		color: $color_1;
		font-size: 55px;
		z-index: 999;
		text-transform: uppercase;
		p {
			padding: 22px 0 4px !important;
		}
	}
	&::before {
		content: url(./images/faq-border-top.png);
		position: absolute;
		left: 0;
		top: -39px;
	}
	&::after {
		content: url(./images/faq-border-bottom.png);
		position: absolute;
		right: 0;
		bottom: -27px;
	}
}
.signup-heading {
	display: inline-block;
	margin: 60px 0 87px;
	position: relative;
	text-align: center;
	width: 85%;
	h1 {
		color: $color_1;
		font-size: 75px;
		z-index: 999;
		text-transform: uppercase;
		color: $color_1;
		font-size: 97px;
		text-transform: uppercase;
		p {
			padding: 22px 0 4px !important;
		}
	}
}
.faq-quesion {
	width: 100%;
	float: left;
	background-color: #111f3c;
	text-align: center;
}
.search-field-outer {
	width: 772px;
	display: inline-block;
	margin-top: 72px;
	.input-group {
		border: 2px solid #cdcdcd;
		-webkit-border-radius: 50px;
		-moz-border-radius: 50px;
		border-radius: 50px;
	}
	button {
		border: 0 !important;
		-webkit-border-top-left-radius: 50px;
		-webkit-border-bottom-left-radius: 50px;
		-moz-border-radius-topleft: 50px;
		-moz-border-radius-bottomleft: 50px;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		padding: 12px 5px 12px 36px;
		&:hover {
			background: #fff;
			outline: none;
		}
		&:focus {
			background: #fff;
			outline: none;
		}
	}
	.glyphicon.glyphicon-search {
		color: $color_7;
		font-size: 20px;
	}
	.form-control {
		border: 0;
		outline: 0 !important;
		-webkit-border-top-right-radius: 50px;
		-webkit-border-bottom-right-radius: 50px;
		-moz-border-radius-topright: 50px;
		-moz-border-radius-bottomright: 50px;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		padding: 12px 15px !important;
		font-family: $font-family_2;
		font-weight: 400;
		height: 49px;
		font-size: 16px;
		&:foucs {
			outline: none !important;
			box-shadow: none !important;
		}
	}
}
.support-faq {
	width: 90px;
	height: 90px;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	background-color: #111f3c;
	text-align: center;
	padding: 20px 0;
	margin: -36px auto;
}
.question-block {
	width: 100%;
	float: left;
	text-align: left;
	padding: 64px 0 0;
	li {
		border-bottom: 1px solid #333f58;
		padding: 27px 0 20px;
		&:last-child {
			border-bottom: none;
		}
		h3 {
			color: $color_7;
			font-size: 20px;
			font-family: $font-family_2;
			font-weight: 700;
			margin-bottom: 7px !important;
			position: relative;
			padding-right: 40px;
		}
		p {
			color: $color_1;
			font-size: 20px;
			font-family: $font-family_2;
			font-weight: 400;
			line-height: 27px;
			margin: 25px 0 0;
			padding-right: 40px;
		}
	}
}
.faq-1 {
	.question-block {
		padding-top: 91px;
		li {
			border-bottom: 0;
			padding-top: 0;
			h3 {
				margin-bottom: 0 !important;
			}
			p {
				margin: 0;
			}
		}
	}
	.search-field-outer {
		.glyphicon.glyphicon-search {
			color: $color_11;
		}
	}
}
.faq-pagination {
	width: 100%;
	float: left;
	text-align: center;
}
.faq-pagination-inner {
	width: 330px;
	display: inline-block;
	border-top: 1px solid #333f58;
	padding: 14px 0 0;
	li {
		display: inline-block;
		font-size: 16px;
		font-family: $font-family_2;
		font-weight: 400;
		color: $color_1;
		padding-right: 25px;
		&:first-child {
			padding-right: 6px;
		}
		&:last-child {
			padding-right: 0;
		}
		a {
			color: $color_8;
			font-size: 20px;
			&:hover {
				text-decoration: none;
				color: $color_9;
			}
		}
	}
}
.related-questions {
	width: 100%;
	float: left;
	text-align: left;
	padding: 16px 0 0;
	h3 {
		font-size: 16px;
		color: $color_10;
		font-family: $font-family_2;
		font-weight: 400;
	}
	li {
		font-size: 14px;
		color: $color_10;
		font-family: $font-family_2;
		font-weight: 400;
		padding-left: 20px;
		background: url(./images/dot-bg.png) 10px 50% no-repeat;
		margin-top: 12px;
	}
}
.faq-4 {
	background-color: #fff;
	.support-faq {
		background-color: #fff;
	}
	.search-field-outer {
		.glyphicon.glyphicon-search {
			color: $color_12;
		}
	}
	.question-block {
		li {
			h3 {
				color: $color_7;
			}
			p {
				color: $color_13;
			}
		}
	}
}
.faq-5 {
	background-color: #fff;
	.support-faq {
		background-color: #fff;
	}
	.question-block {
		li {
			h3 {
				color: $color_11;
			}
			p {
				color: $color_13;
			}
		}
	}
}
.signup-main {
	background: url(./images/signup-banner.jpg) center top no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.my-dashboard {
	width: 100%;
	float: left;
	text-align: center;
	padding: 68px 0 0;
	h3 {
		text-transform: uppercase;
		color: $color_1;
		font-size: 34px;
		margin-bottom: 10px;
	}
	p {
		font-family: $font-family_2;
		font-weight: 400;
		color: $color_14;
		font-size: 20px;
	}
}
.commissioner-block {
	width: 100%;
	float: left;
	text-align: center;
	padding: 78px 0 0;
	li {
		float: left;
		margin-right: 20px;
		position: relative;
		width: 228px;
		&:first-child {
			background: none;
		}
		h3 {
			color: $color_1;
			font-size: 16px;
			padding: 20px 0 19px;
			position: relative;
			display: inline-block;
			text-transform: uppercase;
			margin-bottom: 44px;
			width: 172px;
			background: #ff0d22;
			&::before {
				content: url(./images/red-left-arrow-2.png);
				position: absolute;
				left: -12px;
				top: 0;
			}
			&::after {
				content: url(./images/red-right-arrow-2.png);
				position: absolute;
				right: -12px;
				top: 0;
			}
		}
		p {
			color: $color_1;
			font-size: 16px;
			font-family: $font-family_2;
			font-weight: 400;
		}
		img {
			margin: 18px 0 23px;
		}
	}
}
.dashboard-main {
	background: url(./images/bg-1.jpg) center top no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	width: 100%;
}
.dashboard {
	.logo {
		background: url(./images/border-red-2.png) left bottom no-repeat;
		padding-bottom: 21px;
		img {
			float: left;
		}
		h3 {
			float: left;
			font-size: 28px;
			color: $color_1;
			padding: 46px 0 0 25px;
		}
	}
	nav {
		background-color: #182541;
		width: 508px;
		-webkit-box-shadow: 0px 6px 16px -4px rgba(23,35,50,1);
		-moz-box-shadow: 0px 6px 16px -4px rgba(23,35,50,1);
		box-shadow: 0px 6px 16px -4px rgba(23,35,50,1);
		width: auto !important;
		li {
			padding-left: 5px;
			&:first-child {
				background: url(./images/nav-divider.png) right 14px no-repeat;
				position: relative;
				bdi {
					background-color: #e71424;
					border-radius: 100%;
					display: block;
					font-family: $font-family_3;
					font-size: 9px;
					font-weight: 400;
					height: 12px;
					line-height: 11px;
					position: absolute;
					right: 18px;
					text-align: center;
					top: 28px;
					width: 12px;
				}
			}
			&:last-child {
				background: url(./images/nav-divider.png) left 14px no-repeat;
				padding-right: 25px;
			}
			a {
				&:hover {
					color: $color_1;
					text-decoration: none;
				}
			}
		}
	}
}
.profile {
	width: 192px;
	float: left;
	background-color: #ed1526;
	padding: 7px 10px 7px 18px;
	h3 {
		float: left;
		font-size: 13px;
		color: $color_1;
		padding: 18px 0 0;
	}
	span {
		float: left;
		width: 49px;
		height: 49px;
		margin-left: 10px;
		img {
			width: 100%;
			float: left;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			border-radius: 100%;
		}
	}
	a {
		padding: 0;
		font-size: 13px;
	}
}
.nav.navbar-nav {
	li {
		&:first-child {
			a {
				padding-right: 24px;
			}
		}
	}
}
.dashboard-inner {
	margin: 73px 0 75px;
}
.dashboard-slider {
	width: 100%;
	float: left;
	padding: 50px 0;
	text-align: center;
}
.jspPane {
	height: 100% !important;
	width: 100% !important;
}
.dashboard-box {
	display: inline-block;
	text-align: center;
	margin: 40px 0 0 0;
	background: rgba(42,57,91,1);
	color: '#fff';
	background: -moz-linear-gradient(top, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(42,57,91,1)), color-stop(100%, rgba(7,15,34,0.22)));
	background: -webkit-linear-gradient(top, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);
	background: -o-linear-gradient(top, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);
	background: -ms-linear-gradient(top, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);
	background: linear-gradient(to bottom, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);

	&:first-child {
		margin-left: 0;
	}
	h3 {
		background-color: #ff0e22;
		position: relative;
		padding: 20px 0 21px;
		width: 200px;
		text-align: center;
		color: $color_1;
		font-size: 14px;
		display: inline-block;
		margin-top: -50px;
		margin-bottom: 10px;
		text-transform: uppercase;
		&::before {
			content: url(./images/red-left-arrow-2.png);
			position: absolute;
			left: -12px;
			top: 0;
		}
		&::after {
			content: url(./images/red-right-arrow-2.png);
			position: absolute;
			right: -12px;
			top: 0;
		}
	}
	p {
		font-size: 15px;
		color: $color_3;
		font-family: $font-family_2;
		font-weight: 500;
		line-height: 18px;
		min-height: 36px;
	}
}
.slider-box {
	width: 100%;
	display: inline-block;
	text-align: center;
	margin: -20px 0 0 40px;
	&:first-child {
		margin-left: 0;
	}
	h3 {
		background-color: #ff0e22;
		position: relative;
		padding: 20px 0 21px;
		width: 200px;
		text-align: center;
		color: $color_1;
		font-size: 14px;
		display: inline-block;
		margin-bottom: 24px;
		text-transform: uppercase;
		&::before {
			content: url(./images/red-left-arrow-2.png);
			position: absolute;
			left: -12px;
			top: 0;
		}
		&::after {
			content: url(./images/red-right-arrow-2.png);
			position: absolute;
			right: -12px;
			top: 0;
		}
	}
	p {
		font-size: 15px;
		color: $color_3;
		font-family: $font-family_2;
		font-weight: 500;
		line-height: 18px;
		min-height: 36px;
	}
}
.template-detail-box {
	width: 100%;
	float: left;
	text-align: left;
	padding: 10px;
	border: 1px solid #2b364f;
	background: rgba(42,57,91,1);
	color: '#fff';
	background: -moz-linear-gradient(top, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(42,57,91,1)), color-stop(100%, rgba(7,15,34,0.22)));
	background: -webkit-linear-gradient(top, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);
	background: -o-linear-gradient(top, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);
	background: -ms-linear-gradient(top, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);
	background: linear-gradient(to bottom, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);
	margin: 16px 0 16px;
	position: relative;
}
.dashboard-detail-box-marc {
	width: 100%;
	float: left;
	text-align: left;
	padding: 10px;
	border: 1px solid #2b364f;
	background-color: #121d35;
	padding: 40px 0 0;
	-webkit-box-shadow: 0px -7px 23px -7px rgba(42, 87, 124, 1);
	-moz-box-shadow: 0px -7px 23px -7px rgba(42, 87, 124, 1);
	box-shadow: 0px -7px 23px -7px rgba(42, 87, 124, 1);
	color: '#fff';
	margin: 16px 0 16px;
	position: relative;
}
.dashboard-detail-box {
	width: 100%;
	float: left;
	text-align: left;
	padding: 10px;
	height: 489px;
	border: 1px solid #2b364f;
	background: rgba(42,57,91,1);
	background: -moz-linear-gradient(top, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(42,57,91,1)), color-stop(100%, rgba(7,15,34,0.22)));
	background: -webkit-linear-gradient(top, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);
	background: -o-linear-gradient(top, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);
	background: -ms-linear-gradient(top, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);
	background: linear-gradient(to bottom, rgba(42,57,91,1) 0%, rgba(7,15,34,0.22) 100%);
	margin: 16px 0 41px;
	padding: 10px;
	position: relative;
	li {
		width: 100%;
		float: left;
		color: $color_15;
		font-size: 15px;
		padding: 15px 6px 10px;
		&:nth-child(odd) {
			background-color: #314672;
		}
		&:hover {
			background-color: #ed1526;
			.name-left {
				color: $color_1;
			}
		}
	}
}
.name-left {
	width: auto;
	float: left;
	padding: 8px 15px;
	font-family: $font-family_4;
}
.name-right {
	width: auto;
	float: right;
	text-align: center;
	font-family: $font-family_2;
	font-weight: 500;
	span {
		display: block;
		color: $color_1;
		margin-top: -2px;
	}
}
.admin-game {
	width: 199px;
	display: inline-block;
	position: relative;
	margin-bottom: -32px;
	&::before {
		content: url(./images/grey-left-arrow-2.png);
		position: absolute;
		left: -16px;
		top: 0;
	}
	&::after {
		content: url(./images/blue-right-arrow-2.png);
		position: absolute;
		right: -14px;
		top: 0;
	}
	li {
		font-size: 16px;
		font-family: $font-family_2;
		font-weight: 700;
		text-align: center;
		float: left;
		a {
			display: block;
			padding: 17px 22px 17px 0;
			text-transform: uppercase;
			img {
				margin-right: 10px;
			}
			&:hover {
				text-decoration: none;
			}
		}
		&:first-child {
			a {
				color: $color_16;
				background: #e4e4e4 url(./images/divider.jpg) right 1px no-repeat;
			}
		}
		&:last-child {
			a {
				color: $color_1;
				background: rgba(0,21,50,1);
				background: -moz-linear-gradient(left, rgba(0,21,50,1) 0%, rgba(8,39,73,1) 100%);
				background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,21,50,1)), color-stop(100%, rgba(8,39,73,1)));
				background: -webkit-linear-gradient(left, rgba(0,21,50,1) 0%, rgba(8,39,73,1) 100%);
				background: -o-linear-gradient(left, rgba(0,21,50,1) 0%, rgba(8,39,73,1) 100%);
				background: -ms-linear-gradient(left, rgba(0,21,50,1) 0%, rgba(8,39,73,1) 100%);
				background: linear-gradient(to right, rgba(0,21,50,1) 0%, rgba(8,39,73,1) 100%);
			}
		}
	}
}
.dashboard-2 {
	background: url(./images/bg-2.jpg) center top no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	.dashboard-slider {
		padding: 0 44px 0 68px;
		background: rgba(18,29,53,1);
		background: -moz-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
		background: -webkit-gradient(left top, right top, color-stop(0%, rgba(18,29,53,1)), color-stop(100%, rgba(35,51,84,1)));
		background: -webkit-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
		background: -o-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
		background: -ms-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
		background: linear-gradient(to right, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
		padding: 0 30px;
	}
	.dashboard-detail-box {
		height: 398px;
	}
	.slider-box {
		h3 {
			background-color: #e4e4e4;
			position: relative;
			padding: 19px 0 20px;
			width: 200px;
			text-align: center;
			color: $color_4;
			font-size: 16px;
			display: inline-block;
			margin-bottom: 24px;
			text-transform: uppercase;
			&::before {
				content: url(./images/grey-left-arrow.png);
				left: -15px;
			}
			&::after {
				content: url(./images/grey-right-arrow.png);
				right: -15px;
			}
		}
	}
	.dashboard-inner {
		margin: 73px 0 33px;
	}
}
.slider-box-today {
	h3 {
		background-color: #e4e4e4;
		position: relative;
		padding: 19px 0 20px;
		width: 200px;
		text-align: center;
		color: #000;
		font-size: 16px;
		display: inline-block;
		margin-bottom: 24px;
		text-transform: uppercase;
		&::before {
			content: url(./images/grey-left-arrow.png);
			left: -15px;
		}
		&::after {
			content: url(./images/grey-right-arrow.png);
			right: -15px;
		}
	}
}
.block-outer {
	margin-top: 38px;
	&:first-child {
		margin: 0 0 0;
	}
}
.today-hr {
	.name-left {
		color: $color_1;
		padding: 6px 15px;
	}
	.name-right {
		color: $color_1;
		span {
			display: inline-block;
			background: url(./images/sim-bg.png) center top no-repeat;
			width: 82px;
			height: 36px;
			margin-left: 10px;
			padding: 4px 0 0;
		}
	}
	.dashboard-detail-box {
		margin-top: 20px;
		margin-top: 28px;
	}
}
.standing-block {
	background: rgba(18,29,53,1);
	background: -moz-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(18,29,53,1)), color-stop(100%, rgba(35,51,84,1)));
	background: -webkit-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: -o-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: -ms-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: linear-gradient(to right, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	padding: 0 44px;
	text-align: center;
	display: inline-block;
	h3 {
		background-color: #ff0d21;
		padding: 19px 0 20px;
		color: $color_1;
		font-size: 16px;
		width: 200px;
		text-align: center;
		text-transform: uppercase;
		position: relative;
		margin-top: -20px;
		display: inline-block;
		&:after {
			content: url(./images/red-right-arrow.png);
			position: absolute;
			top: 0;
			right: -15px;
		}
		&:before {
			content: url(./images/red-left-arrow.png);
			position: absolute;
			top: 0;
			left: -15px;
		}
	}
}
.league-left {
	margin-left: 27px;
	&:first-child {
		margin-left: 0;
	}
	h4 {
		text-align: center;
		font-size: 18px;
		color: $color_1;
		background: url(./images/bg-border.png) center 10px repeat-x;
		margin-top: 17px;
		span {
			background-color: #263556;
			padding: 0 10px;
		}
	}
	.name-left {
		img {
			margin-right: 10px;
		}
	}
}
.juli-block {
	height: 440px !important;
	li {
		&:nth-child(odd) {
			background-color: #374e7c;
		}
		&:hover {
			background-color: #ed1526;
		}
	}
}
.player-block {
	padding-top: 17px;
}
.drop-player {
	width: 100%;
	float: left;
	background: rgba(27,40,69,1);
	background: -moz-linear-gradient(left, rgba(27,40,69,1) 0%, rgba(37,53,87,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(27,40,69,1)), color-stop(100%, rgba(37,53,87,1)));
	background: -webkit-linear-gradient(left, rgba(27,40,69,1) 0%, rgba(37,53,87,1) 100%);
	background: -o-linear-gradient(left, rgba(27,40,69,1) 0%, rgba(37,53,87,1) 100%);
	background: -ms-linear-gradient(left, rgba(27,40,69,1) 0%, rgba(37,53,87,1) 100%);
	background: linear-gradient(to right, rgba(27,40,69,1) 0%, rgba(37,53,87,1) 100%);
	-webkit-box-shadow: 0px 0px 21px 6px rgba(29,41,63,1);
	-moz-box-shadow: 0px 0px 21px 6px rgba(29,41,63,1);
	box-shadow: 0px 0px 21px 6px rgba(29,41,63,1);
	position: relative;
	padding: 0 10px 25px;
	h5 {
		font-size: 27px;
		color: $color_1;
		background: url(./images/player-border.png) left bottom no-repeat;
		padding: 60px 0 60px;
		span {
			font-size: 27px;
			color: $color_1;
			font-family: $font-family_2;
			font-weight: 500;
			display: block;
		}
	}
}
.player-add {
	position: absolute;
	right: 20px;
	top: 64px;
}
.add-player {
	margin-top: 27px;
	background: rgba(191,11,26,1);
	background: -moz-linear-gradient(left, rgba(191,11,26,1) 0%, rgba(237,21,39,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(191,11,26,1)), color-stop(100%, rgba(237,21,39,1)));
	background: -webkit-linear-gradient(left, rgba(191,11,26,1) 0%, rgba(237,21,39,1) 100%);
	background: -o-linear-gradient(left, rgba(191,11,26,1) 0%, rgba(237,21,39,1) 100%);
	background: -ms-linear-gradient(left, rgba(191,11,26,1) 0%, rgba(237,21,39,1) 100%);
	background: linear-gradient(to right, rgba(191,11,26,1) 0%, rgba(237,21,39,1) 100%);
	h5 {
		background: url(./images/player-border-blue.png) left bottom no-repeat;
	}
}
.agent-block {
	width: 100%;
	float: left;
	text-align: left;
	height: 440px;
	margin: 33px 0 37px;
	li {
		border-bottom: 1px solid #475870;
		font-size: 15px;
		color: $color_1;
		font-family: $font-family_4;
		padding: 17px 30px 22px;
		&:hover {
			background-color: #ed1526;
		}
	}
}
.free-agent {
	padding: 0 40px !important;
}
#quote-carousel {
	padding: 0 10px 30px 10px;
	.carousel-control {
		background: none;
		color: $color_17;
		font-size: 2.3em;
		text-shadow: none;
	}
	.carousel-control.left {
		left: 0;
	}
	.carousel-control.right {
		right: 0;
	}
	.carousel-indicators {
		right: 50%;
		bottom: 0;
		li {
			margin: 5px;
			cursor: pointer;
			border-radius: 50px;
			overflow: hidden;
			transition: all 0.4s;
		}
		.active {
			background: #fff;
			border-radius: 100px;
			opacity: 1;
			overflow: hidden;
		}
	}
}
.carousel-inner {
	min-height: 300px;
	position: relative;
	width: 100%;
}
.carousel-control {
	top: 35%;
	width: 50px;
	top: 50%;
	width: auto;
	>img {
		left: 26px !important;
		width: 31px !important;
	}
}
.carousel-indicators {
	bottom: 0px !important;
	left: 0;
	list-style: outside none none;
	margin-left: 0;
	text-align: center;
	width: 100%;
}
.dropdown-menu {
	background-clip: padding-box;
	background-color: transparent;
	border: none;
	border-radius: 0;
	box-shadow: none;
	display: none;
	float: left;
	margin: 0;
	min-width: auto;
	padding: 0;
	left: 3px;
}
.dropdown-toggle {
	&:focus {
		outline: 0 none;
		color: $color_1;
		text-decoration: none;
	}
}
.addui-Accordion {
	.addui-Accordion-header {
		&::after {
			content: url(./images/down-arrow.png);
			display: inline-block;
			font-family: $font-family_5;
			font-size: 32px;
			font-style: normal;
			font-weight: normal;
			position: absolute;
			right: 16px;
			text-rendering: auto;
			top: -14px;
			transform: translate(0px, 0px) rotate(0deg);
			transition: transform 0.4s ease 0s;
		}
	}
	.addui-Accordion-header.addui-Accordion-open {
		&::after {
			transform: translate(0px, 0px) rotate(180deg);
		}
	}
}
.carousel-inner.onebyone-carosel {
	margin: auto;
	width: 90%;
}
.onebyone-carosel {
	.active.left {
		left: -33.33%;
	}
	.active.right {
		left: 33.33%;
	}
	.next {
		left: 33.33%;
	}
	.prev {
		left: -33.33%;
	}
}
.navbar-default {
	background-color: transparent;
	border-color: transparent;
	.navbar-nav {
		>li {
			>a {
				&:hover {
					background-color: transparent;
					color: $color_1;
				}
				&:focus {
					background-color: transparent;
					color: $color_1;
				}
				color: $color_1;
			}
		}
	}
}
.navbar {
	min-height: auto;
	border-radius: 0;
}
.nav {
	.open {
		>a {
			border-color: transparent;
			&:hover {
				border-color: transparent;
			}
			&:focus {
				border-color: transparent;
			}
		}
	}
}
.signup {
	.responsive-screen-block {
		display: none;
	}
	.faq-banner {
		&::before {
			display: none;
		}
		&::after {
			display: none;
		}
	}
}
.collapse.navbar-collapse {
	padding: 0;
}
.responsive-dashboard {
	display: none;
}
.jspContainer {
	height: 100% !important;
	width: 100% !important;
}
.carousel-control.left {
	background-image: none !important;
	background-repeat: repeat-x;
}
.carousel-control.right {
	background-image: none !important;
	background-repeat: repeat-x;
}
.carousel-inner-block {
	width: 100%;
	float: left;
	background: rgba(18,29,53,1);
	background: -moz-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(18,29,53,1)), color-stop(100%, rgba(35,51,84,1)));
	background: -webkit-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: -o-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: -ms-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: linear-gradient(to right, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	padding: 0 50px;
	position: relative;
}
.league-main-outer {
	background: rgba(23,35,61,1);
	background: -moz-linear-gradient(left, rgba(23,35,61,1) 0%, rgba(35,51,84,0.76) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(23,35,61,1)), color-stop(100%, rgba(35,51,84,0.76)));
	background: -webkit-linear-gradient(left, rgba(23,35,61,1) 0%, rgba(35,51,84,0.76) 100%);
	background: -o-linear-gradient(left, rgba(23,35,61,1) 0%, rgba(35,51,84,0.76) 100%);
	background: -ms-linear-gradient(left, rgba(23,35,61,1) 0%, rgba(35,51,84,0.76) 100%);
	background: linear-gradient(to right, rgba(23,35,61,1) 0%, rgba(35,51,84,0.76) 100%);
	padding: 50px 30px 52px;
	margin: 50px 0;
}
.block-heading {
	width: 100%;
	display: inline-block;
	text-align: center;
	position: absolute;
	top: -28px;
	left: 0;
	h3 {
		font-size: 16px;
		color: $color_1;
		padding: 19px 0;
		text-align: center;
		text-transform: uppercase;
		font-family: $font-family_1;
		display: inline-block;
		width: 250px;
		background: rgba(225,4,19,1);
		background: -moz-linear-gradient(left, rgba(225,4,19,1) 0%, rgba(255,13,33,0.96) 100%);
		background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225,4,19,1)), color-stop(100%, rgba(255,13,33,0.96)));
		background: -webkit-linear-gradient(left, rgba(225,4,19,1) 0%, rgba(255,13,33,0.96) 100%);
		background: -o-linear-gradient(left, rgba(225,4,19,1) 0%, rgba(255,13,33,0.96) 100%);
		background: -ms-linear-gradient(left, rgba(225,4,19,1) 0%, rgba(255,13,33,0.96) 100%);
		background: linear-gradient(to right, rgba(225,4,19,1) 0%, rgba(255,13,33,0.96) 100%);
		position: relative;
		&:after {
			content: url(./images/after-red-bg.png);
			position: absolute;
			left: -15px;
			top: 0;
		}
		&:before {
			content: url(./images/before-red-bg.png);
			position: absolute;
			right: -15px;
			top: 0;
		}
	}
}
.league-block {
	width: 100%;
	display: inline-block;
	background-color: #121d35;
}
.league-block-inner {
	width: 100%;
	float: left;
}
.league-top-heading {
	width: 100%;
	float: left;
}
.top-heading-block {
	&:first-child {
		width: 81px;
	}
	width: 60.5px;
	height: 46px;
	text-align: center;
	-moz-box-align: center;
	-moz-box-pack: center;
	align-items: center;
	justify-content: center;
	color: $color_1;
	font-size: 17px;
	font-family: $font-family_4;
	background-color: #ed1526;
	float: left;
	display: flex;
	&:nth-child(even) {
		background-color: #ce0c1c;
	}
}
.league-pick-detail {
	width: 100%;
}
.name-box {
	background-color: #ce0c1c;
	width: 81px;
	float: left;
	height: 69px;
	padding: 12px;
	vertical-align: bottom;
	font-size: 10px;
	color: $color_1;
	font-family: $font-family_4;
}
.content-box {
	width: 60.5px;
	float: left;
	line-height: 100%;
	border-right: 1px solid #394154;
	border-bottom: 1px solid #394154;
	position: relative;
	font-size: 10px;
	font-family: $font-family_4;
	color: $color_1;
	height: 69px;
	padding: 5px;
	span {
		width: 22px;
		height: 18px;
		background-color: #4d5668;
		position: absolute;
		bottom: 0;
		right: 0;
		text-align: center;
		padding: 3px 0;
	}
}
.rating-icon {
	width: 100%;
	display: inline-block;
	text-align: right;
	min-height: 11px;
	img {
		display: inline-block;
	}
}
.content-name {
	width: 100%;
	display: inline-block;
	padding: 5px 10px 5px 0;
	h3 {
		font-size: 10px;
	}
}
.active-box {
	background-color: #0d3867;
	span {
		background-color: #4a6a8d;
	}
}
.league-responsive-screen {
	display: none;
}
#css_version {
	&::-webkit-scrollbar {
		width: 11px;
	}
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		-webkit-border-radius: 11px;
		border-radius: 11px;
	}
	&::-webkit-scrollbar-thumb {
		-webkit-border-radius: 11px;
		border-radius: 11px;
		background: rgba(28, 114, 150, 0.8);
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
		&:window-inactive {
			background: rgba(255, 0, 0, 0.4);
		}
	}
}
.scrolldiv {
	width: 100% !important;
	height: 100%;
	overflow: auto;
	padding: 10px;
}
.vertical-track {
	width: 9px;
	-webkit-border-radius: 0;
	border-radius: 0x;
	background-color: #182540;
}
.vertical-handle {
	width: 9px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	background-color: #ed1526;
}
.tabs-menu {
	clear: both;
	border: 1px #ffffff solid;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	li {
		display: inline-block;
		width: 125px;
		a {
			padding: 15px;
			text-transform: uppercase;
			color: $color_1;
			text-decoration: none;
			display: inline-block;
		}
	}
	li.current {
		position: relative;
		background-color: #2e426c;
		z-index: 5;
		&:first-child {
			-webkit-border-top-left-radius: 30px;
			-webkit-border-bottom-left-radius: 30px;
			-moz-border-radius-topleft: 30px;
			-moz-border-radius-bottomleft: 30px;
			border-top-left-radius: 30px;
			border-bottom-left-radius: 30px;
		}
		&:last-child {
			-webkit-border-top-right-radius: 30px;
			-webkit-border-bottom-right-radius: 30px;
			-moz-border-radius-topright: 30px;
			-moz-border-radius-bottomright: 30px;
			border-top-right-radius: 30px;
			border-bottom-right-radius: 30px;
		}
	}
	.current {
		a {
			color: $color_1;
		}
	}
}
.tab_inner {
	width: 100%;
	float: left;
	text-align: center;
	width: 100%;
	float: left;
	text-align: center;
}
.tab {
	float: left;
	margin-bottom: 20px;
	width: 100%;
	float: left;
	margin-bottom: 20px;
	width: 100%;
}
.tab-content {
	width: 100%;
	display: none;
}
#tab-1 {
	display: block;
}
.tabs-menu2 {
	clear: both;
	border: 1px #ffffff solid;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	li {
		display: inline-block;
		width: 125px;
		a {
			padding: 15px;
			text-transform: uppercase;
			color: $color_1;
			text-decoration: none;
			display: inline-block;
		}
	}
	li.current2 {
		&:first-child {
			-webkit-border-top-left-radius: 30px;
			-webkit-border-bottom-left-radius: 30px;
			-moz-border-radius-topleft: 30px;
			-moz-border-radius-bottomleft: 30px;
			border-top-left-radius: 30px;
			border-bottom-left-radius: 30px;
		}
		&:last-child {
			-webkit-border-top-right-radius: 30px;
			-webkit-border-bottom-right-radius: 30px;
			-moz-border-radius-topright: 30px;
			-moz-border-radius-bottomright: 30px;
			border-top-right-radius: 30px;
			border-bottom-right-radius: 30px;
		}
	}
	.current2 {
		a {
			color: $color_1;
		}
	}
}
.current2 {
	position: relative;
	background-color: #2e426c;
	z-index: 5;
}
.tab-content2 {
	width: 100%;
	display: none;
}
#tab-3 {
	display: block;
}
.responsive-dashboard-outer {
	display: none;
}
.responsive-dashboard2 {
	display: none;
}
.left.carousel-control {
	left: 14px;
}
.right.carousel-control {
	right: 15px;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.mobileDashboard {
	li {
		list-style-type: none;
		float: left;
		background-color: #2a35a1;
		border-left: 3px solid #bb121f;
		border-right: 3px solid #bb121f;
		margin-top: 2px;
		margin-bottom: 2px;
		text-align: center;
		width: 100%;
		padding: 1px 1px;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}
	.mobile-hrtoday {
		background-color: #b31515;
	}
	.mobile-left {
		width: auto;
		float: left;
		padding: 8px 15px;
		font-size: 16px;
		font-weight: 500;
	}
	.mobile-right {
		width: auto;
		float: right;
		text-align: center;
		padding: 8px 15px;
		font-size: 16px;
		font-weight: 500;
	}
	.mobile-button {
		border: 0 !important;
		-webkit-border-top-left-radius: 50px;
		-webkit-border-bottom-left-radius: 50px;
		-moz-border-radius-topleft: 50px;
		-moz-border-radius-bottomleft: 50px;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		-webkit-border-top-right-radius: 50px;
		-webkit-border-bottom-right-radius: 50px;
		-moz-border-radius-topright: 50px;
		-moz-border-radius-bottomright: 50px;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	.mobile-league {
		background-color: #ff0e22;

		.mobile-left {
			padding: 15px 15px;
			font-size: 20px;
			font-weight: 800;
		}
		.mobile-right {
			padding: 15px 15px;
			font-size: 20px;
			font-weight: 800;
		}
	}
	.mobile-league-option {
		background-color: #ff0e22;
		opacity: 0.6;

		.mobile-left {
			padding: 13px 13px;
			font-size: 20px;
			font-weight: 700;
		}
		.mobile-right {
			padding: 13px 13px;
			font-size: 20px;
			font-weight: 700;
		}
	}
	.mobile-off {
		display: none;
	}
	.mobile-large {
		padding: 13px 13px;
		margin-top: 20px;
		margin-right: 10px;
		margin-bottom: 200px;
	}
	.mobile-team {
		background-color: #2c6fd4;
		margin-top: 5px;
		margin-bottom: 5px;

		.mobile-left {
			padding: 13px 13px;
			font-size: 17px;
			font-weight: 800;
		}
		.mobile-right {
			padding: 13px 13px;
			font-size: 16px;
			font-weight: 800;
		}
	}
	.mobile-team-option {
		background-color: #2c6fd4;
		opacity: 0.6;
		margin-bottom: 5px;

		.mobile-left {
			padding: 11px 11px;
			font-size: 17px;
			font-weight: 800;
		}
		.mobile-right {
			padding: 11px 11px;
			font-size: 16px;
			font-weight: 800;
		}
	}
}

.game-nav {
	margin-right: 10px;
	margin-bottom: 10px;
}

.dashboard-inner-content {
	margin-right: 100px;
	margin-left: 100px;
	margin-bottom: 10px;
}

.dashboard-top {
	margin-top: 20px;
}

.public-game-message {
	background-color: #b31515;
	border-radius: 15px;
	font-size: 13px;
	padding: 10px 10px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.game-standings {
	background-color: #d9edf7;
	height: 120px;
	border-radius: 25px;
	color: white;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
	padding: 11px 11px;
	margin-right: 10px;
	margin-top: 10px;

	.game-place {
		background-color: #266ad5;
		text-align: center;
		height: 100px;
		width: 100px;
		color: #fff;
		border-radius: 15px;
		text-transform: uppercase;
		font-size: 13px;
		font-weight: 700;
		padding: 11px 11px;

		.big-place {
			font-size: 20px;
			font-weight: 800;
		}
	}

	.game-place-content {
		font-size: 13px;
		color: #000;
		text-transform: none;
		text-align: left;
		margin-top: 10px;
		margin-left: 20px;
	}
}

@media (min-width:320px) {
	/* smartphones, iPhone, portrait 480x320 phones */

	.my-dashboard-header {
		display: none;
	}

	.dashboard-inner-content {
		margin-right: 10px;
		margin-left: 10px;
	}

	.dashboard-box {
		margin-left: -20px;
		width: 110%;
	}

	.game-standings {
		.game-place {
			font-size: 13px;
			margin-right: 10px;

			.big-place {
				font-size: 20px;
			}
		}

		.game-place-content {
			font-size: 13px;
			margin-top: 5px;
		}
	}
}
@media (min-width:641px) {
	/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
	.dashboard-box {
		width: 100%;
	}
}
@media (min-width:961px) {
	/* tablet, landscape iPad, lo-res laptops ands desktops */
	.dashboard-box {
		width: 100%;
	}
}
@media (min-width:1025px) {
	/* big landscape tablets, laptops, and desktops */
	.dashboard-box {
		width: 100%;
	}
}
@media (min-width:1281px) {
	/* hi-res laptops and desktops */
	.dashboard-box {
		width: 100%;
	}
}

@media (min-width:320px) {
	/* smartphones, iPhone, portrait 480x320 phones */
	.home-today-hr {
		margin: 12px 0 -16px;
		padding: 27px 0;
	}
}

.home-today-hr {
	color: #fff;
	a:link {
		color: #fff;
	}
	a:visited {
		color: #fff;
	}
	a:hover {
		color: #fff;
	}
	a:active {
		color: #fff;
	}
	font-family: $font-family_1;
	text-align: left;
	background: rgba(18,29,53,1);
	background: -moz-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(18,29,53,1)), color-stop(100%, rgba(35,51,84,1)));
	background: -webkit-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: -o-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: -ms-linear-gradient(left, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	background: linear-gradient(to right, rgba(18,29,53,1) 0%, rgba(35,51,84,1) 100%);
	text-align: center;
	padding: 27px 48px;
	margin-top: 40px;

	.home-today-hr-header {
		margin-bottom: 10px;
	}
	.home-today-hr-cell {
		text-align: left;
		margin-bottom: 10px;
	}
}
