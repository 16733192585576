body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Raleway-Black';
  src: url('fonts/Raleway-Black.eot');
  src: url('fonts/Raleway-Black.woff2') format('woff2'),
       url('fonts/Raleway-Black.woff') format('woff'),
       url('fonts/Raleway-Black.ttf') format('truetype'),
       url('fonts/Raleway-Black.svg#Raleway-Black') format('svg'),
       url('fonts/Raleway-Black.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sansation-Regular';
  src: url('fonts/Sansation-Regular.eot');
  src: url('fonts/Sansation-Regular.woff2') format('woff2'),
       url('fonts/Sansation-Regular.woff') format('woff'),
       url('fonts/Sansation-Regular.ttf') format('truetype'),
       url('fonts/Sansation-Regular.svg#Sansation-Regular') format('svg'),
       url('fonts/Sansation-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
