.grid-pick {
  font-family: 'Arial';
  font-size: 13px;
  padding: 5px;
  text-align: left;
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  color: #000;
}

.grid-pick-hr-tot {
  text-align: right;
}
